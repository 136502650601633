import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex py-3 px-4 highlight items-center leading-5 text-base" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "flex-auto" }
const _hoisted_4 = { class: "text-gray text-sm flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalLink = _resolveComponent("BalLink")!
  const _component_BalToggle = _resolveComponent("BalToggle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx._url($props.tokenlist.logoURI),
      class: "rounded-full inline-block align-middle mr-3",
      width: "34",
      height: "34"
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createTextVNode(_toDisplayString($props.tokenlist.name) + " ", 1),
      _createElementVNode("div", _hoisted_4, [
        _createTextVNode(_toDisplayString($setup.fNum2($props.tokenlist.tokens.length, {
            style: 'decimal',
            maximumFractionDigits: 1,
            abbreviate: true
          })) + " " + _toDisplayString(_ctx.$t('tokensLowerCase')) + " ", 1),
        _createVNode(_component_BalLink, {
          href: _ctx.listUrl,
          external: "",
          class: "flex items-center"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BalIcon, {
              name: "arrow-up-right",
              size: "sm",
              class: "ml-1 text-gray-500 hover:text-blue-500 transition-colors"
            })
          ]),
          _: 1
        }, 8, ["href"])
      ])
    ]),
    (_ctx.notBalancer)
      ? (_openBlock(), _createBlock(_component_BalToggle, {
          key: 0,
          name: "active",
          checked: $props.isActive,
          onToggle: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggle')))
        }, null, 8, ["checked"]))
      : _createCommentVNode("", true)
  ]))
}